/** @jsx jsx */
import { jsx, css } from "@emotion/react";
import textBoxFrameSmallWhite from "../../resources/images/textBoxFrameSmallWhite.png";
import textBoxFrameSmallBlack from "../../resources/images/textBoxFrameSmallBlack.png";
import { Container } from "react-bootstrap";
import { fonts } from "../../resources/styling";

export default function SectionHeader({ image, title, underline, white }) {
  const imageStyleSmall = css({
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    height: `50px`,
    margin: "0px",
    padding: "0",
    width: "100%",
    backgroundImage: image
      ? `url(${white ? textBoxFrameSmallWhite : textBoxFrameSmallBlack})`
      : "",
    marginBottom: "10px",
  });

  return (
    <Container fluid className='p-0 m-0 pt-4'>
      <div css={[imageStyleSmall, { display: "block", width: "100%" }]} />
      <div
        className="d-block text-center p-0 m-0"
        css={[
          fonts.mainHeader,
          {
            color: white ? "white" : "black",
          },
          underline
            ? {
                textDecoration:
                  "underline 2px Solid " + white ? "white" : "black",
              }
            : {},
        ]}
      >
        {title}
      </div>
    </Container>
  );
}
