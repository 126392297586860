/** @jsx jsx */
import { jsx } from "@emotion/react";
import { Col, Row } from "react-bootstrap";
import { SmallContentBox } from "../utils/ContentBox";
import SectionHeader from "../utils/SectionHeader";
import AccomodationItem from "./AccomodationItem";
import { Page } from "../utils/Page";
import magalies from '../../resources/images/magalies.png'
import greyEagle from '../../resources/images/GreyEagle.png'
import villaBaragha from '../../resources/images/VillaBaragha.png'
import OnsPlek from '../../resources/images/OnsPlek.jpg'
import { sizing } from "../../resources/styling";

const Accomodation = () => {
  return (
    <Page headerVisible={true} color={'white'}>
      <SectionHeader title="Accomodation" underline={true} image={true} white={false}/>
      <Row fluid={'true'}  className="w-100 p-0 m-0 pl-3 pr-3 pb-lg-4" css={sizing.fullInnerHeight}>
        <Col lg={12} xl={6} className="m-0 mt-3 mb-xl-0 p-0 pr-xl-4 h-lg-100" >
          <SmallContentBox>
            <AccomodationItem title="Magalies Mountain Lodge"
              image={magalies}
              phone="+27 12 534 3078"
              email="info@magaliesmountainlodge.co.za"
              distance="5km"
              link="https://www.magaliesmountainlodge.co.za/"
            />
          </SmallContentBox>
        </Col>
        <Col lg={12} xl={6} className="m-0 mt-3 mb-xl-0 p-0 pl-xl-4" >
          <SmallContentBox >
          <AccomodationItem title="Villa Baragha Country Estate"
              image={villaBaragha}
              phone="+27 83 227 8412"
              email="villabaragha1@gmail.com"
              distance="1.5km"
              link="http://www.villabaragha.co.za/"
            />
          </SmallContentBox>
        </Col>
        <Col lg={12} xl={6} className="m-0 mt-3 mb-xl-0 p-0 pr-xl-4" >
          <SmallContentBox>
          <AccomodationItem title="Grey Eagle B & B"
              image={greyEagle}
              phone="+27 82 406 5223"
              email="linda.greyeagle@gmail.com"
              distance="500m"
              link="https://www.greyeagle-bb.com/"
            />
          </SmallContentBox>
        </Col>
        <Col lg={12} xl={6} className="m-0 mt-3 mb-xl-0 p-0 pl-xl-4">
          <SmallContentBox >
          <AccomodationItem title="Ons Plek"
              image={OnsPlek}
              phone="+27 82 449 4873"
              email="jennymuller@tiscali.co.za"
              distance="500m"
              link="https://www.facebook.com/OnsPlekCountryKitchen/?ref=page_internal"
            />
          </SmallContentBox>
        </Col>
      </Row>
    </Page>
  );
};

export default Accomodation;
