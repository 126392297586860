/** @jsx jsx */
import { jsx } from "@emotion/react";
import Zoom from "react-reveal/Zoom";
import styling from "./rsvpStyling"

export const RsvpItem = ({
  wrapper,
  fillColor,
  children,
  color,
  css
}) => {
  return (
    <div className="d-md-block" css={[wrapper,css]}>
      <Zoom duration={500}>
        <div css={styling.item}>
          <div css={styling.outer} className="mx-auto m-md-0">
            <div css={[styling.inner, { backgroundColor: fillColor, color: color}]}>
              {children}
            </div>
          </div>
        </div>
      </Zoom>
    </div>
  );
};

export default RsvpItem;