/** @jsx jsx */
import { jsx } from "@emotion/react";
import { Button, Col, Form } from "react-bootstrap";
import RsvpItem from "./RsvpItem";
import { useState } from "react";
import styling from "./rsvpStyling"
import * as emailJs from "emailjs-com";
import { colors, fonts } from "../../resources/styling";
import "../../resources/select-css.css";

const RsvpBody = ({toggleSubmit}) => {
  const [RsvpInformation, setRsvpInformation] = useState({
    name: "",
    email: "",
    contact: "",
    attending: "No",
    partner: "No",
    kids: "No",
    kidsCount: 0,
    notes: "",
  });

  const [kidsDisabled, setKidsDisabled] = useState(true);
  const handleSubmit = (e) => {
    e.preventDefault();

    const userId = process.env.REACT_APP_emailJs_UserID;
    const serviceID = process.env.REACT_APP_emailJs_ServiceID;
    
    const message = `
        The following person as RSVP'ed: \n
        \n
        Name: ${RsvpInformation.name} \n
        Email: ${RsvpInformation.email} \n
        Contact: ${RsvpInformation.contact} \n
        Attending: ${RsvpInformation.attending} \n
        Partner: ${RsvpInformation.partner} \n
        Kids: ${RsvpInformation.kids} \n
        Kids Count: ${RsvpInformation.kidsCount} \n
        Notes: ${RsvpInformation.notes}
			`;

    const templateParams = {
      from_name: RsvpInformation.email,
      to_name: "nlynzaad@zylangroup.com",
      subject: "RSVP Notification",
      message: message,
    };

    emailJs.send(
      serviceID,
      "template_334r7xs",
      templateParams,
      userId
    );

    toggleSubmit(true);
  };

  const handleChange = (param, e) => {
    if (param === "kids") {
      setKidsDisabled(e.target.value === "No");
    }

    setRsvpInformation({ ...RsvpInformation, [param]: e.target.value });
  };

  return (
    <RsvpItem wrapper={styling.wrapper} fillColor={colors.bck_dark}>
      <Form className="pt-3 text-left m-0" onSubmit={handleSubmit}>
        <Form.Row className="pb-3">
          <Form.Label column xs={12} lg="7" css={fonts.normalText}>
            Name
          </Form.Label>
          <Col xs={12} lg="5" className="pl-sm-2">
            <Form.Control
              type="text"
              onChange={handleChange.bind(this, "name")}
              placeholder="Name"
              name="name"
              value={RsvpInformation.name}
              required
            />
          </Col>
        </Form.Row>

        <Form.Row className="pb-3">
          <Form.Label column xs={12} lg="7">
            Email
          </Form.Label>
          <Col xs={12} lg="5">
            <Form.Control
              type="email"
              placeholder="Email"
              onChange={handleChange.bind(this, "email")}
              name="email"
              value={RsvpInformation.email}
              required
            />
          </Col>
        </Form.Row>

        <Form.Row className="pb-3">
          <Form.Label xs={12} column lg="7">
            Contact Number
          </Form.Label>
          <Col xs={12} lg="5">
            <Form.Control
              type="tel"
              placeholder="Contact number"
              onChange={handleChange.bind(this, "contact")}
              name="contact"
              value={RsvpInformation.contact}
              required
            />
          </Col>
        </Form.Row>
        <Form.Row className="pb-3">
          <Form.Label as="legend" column xs={12} lg="7">
            Will you be attending?
          </Form.Label>
          <Col xs={12} lg="5">
            <Form.Control
              as="select"
              className="select-css"
              type="select"
              onChange={handleChange.bind(this, "attending")}
              name="attending"
              value={RsvpInformation.attending}
            >
              <option value={"No"}>No</option>
              <option value={"Yes"}>Yes</option>
            </Form.Control>
          </Col>
        </Form.Row>
        <Form.Row className="pb-3">
          <Form.Label as="legend" column xs={12} lg="7">
            Will your partner be joining us?
          </Form.Label>
          <Col xs={12} lg="5">
            <Form.Control
              as="select"
              className="select-css"
              type="select"
              onChange={handleChange.bind(this, "partner")}
              name="partner"
              value={RsvpInformation.partner}
            >
              <option value={"No"}>No</option>
              <option value={"Yes"}>Yes</option>
            </Form.Control>
          </Col>
        </Form.Row>
        <Form.Row className="pb-3">
          <Form.Label as="legend" column xs={12} lg="7">
            Will your kids be joining us?
          </Form.Label>
          <Col xs={12} lg="5">
            <Form.Control
              as="select"
              className="select-css"
              type="select"
              onChange={handleChange.bind(this, "kids")}
              name="kids"
            >
              <option value={"No"} onChange={handleChange.bind(this, "kids")}>
                No
              </option>
              <option value={"Yes"} onChange={handleChange.bind(this, "kids")}>
                Yes
              </option>
            </Form.Control>
          </Col>
        </Form.Row>
        <Form.Row className="pb-3">
          <Form.Label
            className={kidsDisabled ? "text-muted" : ""}
            as="legend"
            column
            xs={12}
            lg="7"
          >
            Number of kids?
          </Form.Label>
          <Col xs={12} lg="5">
            <Form.Control
              disabled={kidsDisabled}
              muted
              as="select"
              className="select-css"
              onChange={handleChange.bind(this, "kidsCount")}
              name="kidsCount"
            >
              <option value={1}>1</option>
              <option value={2}>2</option>
              <option value={3}>3</option>
              <option value={4}>4</option>
              <option value={5}>5</option>
            </Form.Control>
          </Col>
        </Form.Row>
        <Form.Group className="pb-3">
          <Form.Label>Any Special Notes?</Form.Label>
          <Form.Control
            as="textarea"
            placeholder="Notes"
            onChange={handleChange.bind(this, "notes")}
            value={RsvpInformation.notes}
          />
        </Form.Group>
        <Button variant="primary" type="submit" rows={3}>
          Submit
        </Button>
      </Form>
    </RsvpItem>
  );
};

export default RsvpBody;