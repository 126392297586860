/** @jsx jsx */
import { jsx } from "@emotion/react";
import { Container } from "react-bootstrap";
import { ContentBox } from "../utils/ContentBox";
import SectionHeader from "../utils/SectionHeader";
import { Page } from "../utils/Page";
import { colors, fonts } from "../../resources/styling";
import { useState } from "react";
import styling from "./rsvpStyling";
import RsvpBody from "./rsvpBody";
import SubmittedBody from "./submittedBody";
import { getInnerWindowHeight } from "../utils/Helpers";

const Rsvp = () => {
  const [submitted, setSubmitted] = useState(false);

  const toggleSubmit = (submit) => {
    setSubmitted(submit);
  };

  return (
    <Page headerVisible={true} color={colors.bck_dark}>
      <SectionHeader title="RSVP" underline={true} image={true} white={true} />

      <ContentBox noShadow={true} className="pl-3 pr-3 pb-3">
        <Container
          fluid
          className="d-flex align-items-center justify-content-center align-content-center flex-wrap m-0 mt-2 p-0 pt-2"
          css={[
            {
              border: "2px solid white",
                minHeight: `${getInnerWindowHeight() + 50}px`,
                height: "100%"
            },
            styling.background,
            fonts.normalText,
          ]}
        >
          {submitted ? <SubmittedBody toggleSubmit={toggleSubmit} /> : <RsvpBody toggleSubmit={toggleSubmit} />}
        </Container>
      </ContentBox>
    </Page>
  );
};

export default Rsvp;
