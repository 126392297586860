/** @jsx jsx */
import { jsx, css } from '@emotion/react';
import { Container } from 'react-bootstrap';
import { WhenWhereItem } from './whenWhereItem';
import icon_calendar from '../../resources/images/icons/calendar.png';
import icon_location from '../../resources/images/icons/location.png';
import venue from '../../resources/images/LouigGiano.jpg';
import { colors } from '../../resources/styling';
import SectionHeader from '../utils/SectionHeader';
import { openInMap } from '../utils/Helpers';
import { Page } from '../utils/Page';
import { ContentBox } from '../utils/ContentBox';
import { sizing } from '../../resources/styling';
import { getInnerWindowHeight } from '../utils/Helpers';

const styling = {
	wrapper: css({
		padding: '0',
		paddingTop: '70px',
		margin: 'auto',
	}),
	background: css({
		backgroundImage: `url(${venue})`,
		backgroundSize: 'cover',
		backgroundRepeat: 'no-repeat',
		backgroundPosition: 'center',
		'@media (min-width: 1201px)': {
			marginTop: '50px',
			marginLeft: 'auto',
		},
	}),
};

const WhenWhere = () => {
	return (
		<Page color={colors.bck_dark} headerVisible={true}>
			<SectionHeader title='When & Where' underline={true} white={true} image={true} />
			<ContentBox noShadow={true} className='pl-3 pr-3 pt-0 m-0' css={{ height: `${sizing.fullInnerHeight}` }}>
				<Container
					fluid={'true'}
					className='d-flex align-items-center align-content-center flex-wrap m-0 mt-2 p-0 h-100'
					css={[
						{
							border: '2px solid white',
							minHeight: `${getInnerWindowHeight() + 50}px`,
							height: '100%',
						},
						styling.background,
					]}>
					<WhenWhereItem
						wrapper={styling.wrapper}
						fillColor={colors.bck_blue}
						icon={icon_location}
						title='Event Location'
						description='Louig Giano Wedding Village, 9 Roux Lane, Enormwater AH'
						iconFill={colors.bck_pale_pink}
						click={() => openInMap(-25.704486588608788, 28.028569179236605)}
						className='pr-3 pl-3'
					/>
					<WhenWhereItem
						wrapper={styling.wrapper}
						fillColor={colors.bck_pale_pink}
						icon={icon_calendar}
						title='Event Date & Time'
						description='16 April, 15:00pm'
						iconFill={colors.bck_blue}
						className='pr-3 pl-3'
					/>
				</Container>
			</ContentBox>
		</Page>
	);
};

export default WhenWhere;
