export const openInMap = (lat, long) => {
  if /* if we're on iOS, open in Apple Maps */
    ((navigator.platform.indexOf("iPhone") !== -1) || 
     (navigator.platform.indexOf("iPad") !== -1) || 
     (navigator.platform.indexOf("iPod") !== -1))
    window.open(`maps://maps.google.com/maps?daddr=${lat},${long}&amp;ll=`);else /* else use Google */
    window.open(`https://maps.google.com/maps?daddr=${lat},${long}&amp;ll=`);
}

const getScrollBarWidth = () => {
  var inner = document.createElement("p");
  inner.style.width = "100%";
  inner.style.height = "200px";

  var outer = document.createElement("div");
  outer.style.position = "absolute";
  outer.style.top = "0px";
  outer.style.left = "0px";
  outer.style.visibility = "hidden";
  outer.style.width = "200px";
  outer.style.height = "150px";
  outer.style.overflow = "hidden";
  outer.appendChild(inner);

  document.body.appendChild(outer);
  var w1 = inner.offsetWidth;
  outer.style.overflow = "scroll";
  var w2 = inner.offsetWidth;
  if (w1 === w2) w2 = outer.clientWidth;

  document.body.removeChild(outer);

  return w1 - w2;
};

export const getWindowHeight = () => {
  return window.innerHeight > document.documentElement.clientHeight
    ? window.innerHeight - getScrollBarWidth()
    : window.innerHeight;
};

export const getInnerWindowHeight = () => getWindowHeight() - 300;

export const getWindowWidth = () => {
  return window.innerHeight > document.documentElement.clientHeight
    ? window.innerHeight - getScrollBarWidth()
    : window.innerHeight;
};
