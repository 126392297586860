/** @jsx jsx */
import { jsx, css } from "@emotion/react";
import Zoom from "react-reveal/Zoom";

const ItemStyling = {
  item: css({
    width: "50%",
    color: "#ffffff",
    textAlign: "center",
  }),
  outer: css({
    width: "300px",
    "@media (max-width: 768px)": {
      width: "200px",
    },
    border: "2px solid white",
    margin: "auto",
    padding: "5px",
  }),
  inner: css({
    border: "2px solid white",
    position: "relative",
    padding: "15px",
    paddingTop: '5px',
    transition: "all 500ms ease",
  }),
};

export const MenuItem = ({
  wrapper,
  fillColor,
  children,
  color
}) => {
  return (
    <div className="d-md-block justify-content-between" css={wrapper}>
      <Zoom duration={500}>
        <div css={ItemStyling.item}>
          <div css={ItemStyling.outer} className="mx-auto m-md-0">
            <div css={[ItemStyling.inner, { backgroundColor: fillColor, color: color}]}>
              {children}
            </div>
          </div>
        </div>
      </Zoom>
    </div>
  );
};

export default MenuItem;