/** @jsx jsx */
import { jsx } from "@emotion/react";
import { Container } from "react-bootstrap";
import GiftBox from "../../resources/images/GiftBox3.jpg";
import { fonts } from "../../resources/styling";

export const GiftRegistry = () => {
  return (
    <Container fluid className="m-0 p-0 pl-5 pr-5 h-100">
      <Container className="p-0 m-0">
        <h4
          css={fonts.boxHeader}
        >
          Gift Registery
        </h4>
        <p className="p-0 m-0 pb-xl-3"
          css={[fonts.normalText, fonts.centered]}
        >
          Our life together has already begun, and we have gathered most of what
          we want. If you were thinking of buying a gift, a little extra money
          would give us a lift. But most importantly, we request, you share our
          day as our wedding guest.
        </p>
      </Container>
      <div
        className="m-0 p-0"
        css={{
          backgroundImage: `url(${GiftBox})`,
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          height: "400px",
          margin: '0px',
          padding: '0px',
          "@media (max-width: 360px)": {
            marginTop: "50px",
            marginLeft: "auto",
            height: "250px",
          },
          "@media (min-width: 1441px)": {
            marginTop: "50px",
            marginLeft: "auto",
            height: "420px",
          },
        }}
      />
    </Container>
  );
};

export default GiftRegistry;
