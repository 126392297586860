/** @jsx jsx */
import { jsx } from "@emotion/react";
import { Col, Row } from "react-bootstrap";
import { ContentBox } from "../utils/ContentBox";
import SectionHeader from "../utils/SectionHeader";
import DressCode from "./DressCode";
import GiftRegistry from "./GiftRegistry";
import { Page } from "../utils/Page";

const Guests = () => {
  return (
    <Page headerVisible={true} color={'white'}>
      <SectionHeader title="Additional Information" underline={true} image={true} white={false}/>
      <Row fluid={'true'} className="w-100 p-0 m-0 pl-3 pr-3">
        <Col lg={12} xl={6} className="m-0 mt-3 h-100 mb-xl-0 p-0 pr-xl-4">
          <ContentBox className="pb-3">
            <GiftRegistry />
          </ContentBox>
        </Col>
        <Col lg={12} xl={6} className="m-0 mt-3 h-100 mb-xl-0 p-0 pl-xl-4">
          <ContentBox className="pb-3">
            <DressCode />
          </ContentBox>
        </Col>
      </Row>
    </Page>
  );
};

export default Guests;
