/** @jsx jsx */
import { jsx } from "@emotion/react";
import { Button } from "react-bootstrap";
import RsvpItem from "./RsvpItem";
import styling from "./rsvpStyling"
import { colors } from "../../resources/styling";

const submittedBody = ({toggleSubmit}) => {
  const reset = () => {
    toggleSubmit(false);
  }

  return (
    <RsvpItem wrapper={styling.wrapper} fillColor={colors.bck_dark} >
      <div className="mt-4 mb-4">
        Thank You For Submitting your RSVP.
        <br />
        <br />
        Please remember to check back regularly for any updates.
      </div>
      <Button className="mb-4" variant="primary" onClick={reset} rows={3}>
        Reset
      </Button>
    </RsvpItem>
  )
}
export default submittedBody;