/** @jsx jsx */
import { jsx } from '@emotion/react';
import Fade from 'react-reveal/Fade';
import { fonts, colors } from '../../resources/styling';

const Footer = () => {
	return (
		<footer className='p-0 m-0' css={{ backgroundColor: colors.bck_dark }}>
			<Fade delay={500}>
				<div css={[fonts.normalText, fonts.centered]}>All rights reserved.</div>
				<div css={[fonts.normalText, fonts.centered]}>
					Engagement Photoshoot:&nbsp;
					<a href='https://www.facebook.com/anelpotgieterphotography' css={[fonts.centered]}>
						Anel Potgieter Wedding Photography
					</a>
				</div>
			</Fade>
		</footer>
	);
};

export default Footer;
