/** @jsx jsx */
import { jsx, css } from "@emotion/react";
import { useState, useEffect } from "react";
import Slide from "react-reveal/Slide";
import { colors } from "../../resources/styling";

const styling = {
  countDownStyle: css({
    position: "absolute",
    marginBottom: 0,
    paddingBottom: 0,
    alignContent: "left",
    width: "370px",
    fontSize: "20px",
    overflow: "hidden",
    bottom: "0px",
    justifyContent: "center",
  }),
  top: css({
    color: [colors.bck_blue],
    fontWeight: "bold",
    padding: "10px",
    display: "inline-block",
    textTransform: "uppercase",
    backgroundColor: "#dedede",
    backgroundSize: "contain",
  }),
  bottom: css({
    display: "flex",
    background: [colors.silverGradient],
    backgroundColor: "white",
    backgroundSize: "contain",
    padding: "10px 20px",
  }),
  time: css({
    borderRight: `2px solid ${[colors.bck_pink]}`,
    marginRight: "14px",
    paddingRight: "49px",
    color: [colors.bck_blue],
    "&:last-child": {
      border: "none",
    },
  }),
  lastTime: css({
    borderRight: "none",
    marginRight: "14px",
    paddingRight: "49px",
    color: [colors.bck_blue],
  }),
  tag: css({
    textTransform: "uppercase",
    fontWeight: "bold",
    paddingLeft: "0",
    marginLeft: "0",
    textAlign: "left",
    paddingRight: "49px",
    color: [colors.bck_blue],
  }),
};

export const TimeUntil = () => {
  const [timeUntil, setTimeUntil] = useState({
    deadline: "2021/04/16 15:30",
    days: "0",
    hours: "0",
    minutes: "0",
    seconds: "0",
  });

  const getTimeUntil = (deadline) => {
    const time = Date.parse(deadline) - Date.parse(new Date());
    
    if (time < 0) {
      console.log("Date passed");
    } else {
      const seconds = Math.floor((time / 1000) % 60);
      const minutes = Math.floor((time / 1000 / 60) % 60);
      const hours = Math.floor((time / (1000 * 60 * 60)) % 24);
      const days = Math.floor(time / (1000 * 60 * 60 * 24));

      setTimeUntil({
        days,
        hours,
        minutes,
        seconds,
      });
    }
  };

  useEffect(() => {
    const interval = setInterval(() => getTimeUntil(timeUntil.deadline), 1000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div css={styling.countDownStyle} className="p-0 m-0">
      <Slide left delay={1000}>
        <div>
          <div css={styling.top}>Event starts in</div>
          <div css={styling.bottom}>
            <div css={styling.item}>
              <div css={styling.time}>{timeUntil.days}</div>
              <div css={styling.tag}>Days</div>
            </div>
            <div css={styling.item}>
              <div css={styling.time}>{timeUntil.hours}</div>
              <div css={styling.tag}>Hrs</div>
            </div>
            <div css={styling.item}>
              <div css={styling.time}>{timeUntil.minutes}</div>
              <div css={styling.tag}>Min</div>
            </div>
            <div css={styling.item}>
              <div css={styling.lastTime}>{timeUntil.seconds}</div>
              <div css={styling.tag}>Sec</div>
            </div>
          </div>
        </div>
      </Slide>
    </div>
  );
};

export default TimeUntil;
