/** @jsx jsx */
import { jsx } from "@emotion/react";
import { Container } from "react-bootstrap";
import { colors, shadows } from "../../resources/styling";
import Fade from "react-reveal/Fade";
import { getInnerWindowHeight } from "../utils/Helpers";

export const ContentBox = ({
  children,
  noShadow,
  className,
  css,
  color,
  backgroundColor,
}) => {
  return (
    <Container
      fluid
      className={`p-0 m-0 pt-2 ${className}`}
      name='contentbox'
      css={[
        {
          backgroundColor: backgroundColor
            ? backgroundColor
            : [colors.bck_dark],
          color: color ? color : "white",
          "@media (min-width: 1201px)": {
            minHeight: `${getInnerWindowHeight()}px`,
            height: `${getInnerWindowHeight()}px`,
          },
          "@media (max-width: 1200px)": {
            height: "100%"
          },
        },
        !noShadow ? { boxShadow: [shadows.normal] } : {},
        css ? css : {},
      ]}
    > 
      <Fade>{children}</Fade>
    </Container>
  );
};

export const SmallContentBox = ({
  children,
  noShadow,
  className,
  css,
  color,
  backgroundColor,
}) => {
  return (
    <Container
      fluid
      className={`m-0 h-100 p-0 pt-2 ${className}`}
      css={[
        {
          backgroundColor: backgroundColor
            ? backgroundColor
            : [colors.bck_dark],
          color: color ? color : "white",
          minHeight: `${getInnerWindowHeight() * 0.45}px`,
          height: `${getInnerWindowHeight() * 0.45}px`,
        },
        !noShadow ? { boxShadow: [shadows.normal] } : {},
        css ? css : {},
      ]}
    >
      <Fade>{children}</Fade>
    </Container>
  );
};
