/** @jsx jsx */
import { jsx, css } from "@emotion/react";
import { Container } from "react-bootstrap";
import { ContentBox } from "../utils/ContentBox";
import SectionHeader from "../utils/SectionHeader";
import { Page } from "../utils/Page";
import { colors, fonts } from "../../resources/styling";
import food from "../../resources/images/food.jpg";
import MenuItem from "./MenuItem";
import { getInnerWindowHeight } from "../utils/Helpers";

const styling = {
  wrapper: css({
    padding: "0",
    paddingTop: "20px",
    paddingBottom: "20px",
    margin: "auto",
    "@media (max-width: 625px)": {
      paddingTop: "120px",
      paddingBottom: "50px",
    },
    "@media (min-width: 1441px)": {
      margin: "0",
      marginLeft: "auto",
      marginRight: "auto",
    },
  }),
  background: css({
    backgroundImage: `url(${food})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    "@media (min-width: 1441px)": {
      marginTop: "50px",
      marginLeft: "auto",
    },
  }),
};

const Menu = () => {
  return (
    <Page headerVisible={true} color={colors.bck_dark}>
      <SectionHeader
        title="Menu For The Day"
        underline={true}
        image={true}
        white={true}
      />

      <ContentBox noShadow={true} className="pl-3 pr-3">
        <Container
          fluid
          className="d-flex align-items-center align-content-center flex-wrap m-0 mt-2 p-0 pt-2"
          css={[
            {
              border: "2px solid white",
              minHeight: `${getInnerWindowHeight() + 50}px`,
              height: "100%"
            },
            styling.background,
          ]}
        >
          <MenuItem wrapper={styling.wrapper} fillColor={colors.bck_dark}>
            <p className="p-0 m-0" css={fonts.sectionHeader}>
              Starters
            </p>
            <p className="p-0 m-0" css={fonts.normalText}>
              - Freshly Baked Meatloaf with flavoured Cottage Cheese
              <br />- Choice of Aperitifs: sherry, old-fashioned lemonade, fruit
              juice & cocktail
            </p>
          </MenuItem>
          <MenuItem
            wrapper={styling.wrapper}
            fillColor={colors.bck_dark}
            color={"white"}
          >
            <p className="p-0 m-0 pt-2" css={fonts.sectionHeader}>
              Main Course
            </p>
            <p className="p-0 m-0 pt-2" css={fonts.normalText}>
              <span css={[fonts.normalText, fonts.underlined]}>Meats</span>
              <br />
              - Beefroast slices with Black Pepper Cream Sauce & Rosemary
              <br />
              - Chicken Pie
              <br />
            </p>
            <p className="p-0 m-0 pt-2" css={fonts.normalText}>
              <span css={[fonts.normalText, fonts.underlined]}>Vegetables</span>
              <br />
              - Pumpkin Fritters with Fudge Caramel Sauce
              <br />
              - Sweet Carrot Rings
              <br />
            </p>
            <p className="p-0 m-0 pt-2" css={fonts.normalText}>
              <span css={[fonts.normalText, fonts.underlined]}>Starch</span>
              <br />
              - Oven Baked Potatoes with Mushroom & Cheddar Cheese
              <br />
              - PlainWhite Rice & Brown Sauce
              <br />
            </p>
            <p className="p-0 m-0 pt-2" css={fonts.normalText}>
              <span css={[fonts.normalText, fonts.underlined]}>Salad</span>
              <br />
              - Green Salad
              <br />
            </p>
            <p className="p-0 m-0" css={fonts.sectionHeader}>
              Desert
            </p>
            <p className="p-0 m-0" css={fonts.normalText}>
              - Peppermint Crisp Tart
              <br />- Cheesecake
            </p>
          </MenuItem>
          <MenuItem wrapper={styling.wrapper} fillColor={colors.bck_dark}>
            <p className="p-0 m-0 pt-2" css={fonts.sectionHeader}>
              Drinks
            </p>
            <p className="p-0 m-0" css={fonts.normalText}>
              - 1 x Champagne per table
              <br />
              - 1 x Fruit Juice per table
              <br />
              - Coffee & Tea
              <br />
              - Cash Bar
              <br />
            </p>
          </MenuItem>
        </Container>
      </ContentBox>
    </Page>
  );
};

export default Menu;
