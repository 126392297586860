/** @jsx jsx */
import { jsx } from "@emotion/react";
import { Container } from "react-bootstrap";
import SmartFormal from "../../resources/images/SmartFormal2.jpg";
import { fonts } from "../../resources/styling";

export const DressCode = () => {
  return (
    <Container fluid className="m-0 p-0 pl-5 pr-5 h-100">
      <Container className="p-0 m-0">
        <h4
          css={fonts.boxHeader}
        >
          Dress Code
        </h4>
        <p className="p-0 m-0 pb-xl-3"
          css={[fonts.normalText, fonts.centered]}
        >
          This is a very special day for us and we want everyone to be as comfortable and beautifull/handsome as possible. To make sure everyone looks dashingly smart we ask that
          everyone please dress in smart formal attire.
        </p>
      </Container>
      <div
        className="m-0 p-0"
        css={{
          backgroundImage: `url(${SmartFormal})`,
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          height: "400px",
          margin: '0px',
          padding: '0px',
          "@media (max-width: 360px)": {
            marginTop: "50px",
            marginLeft: "auto",
            height: "250px",
          },
          "@media (min-width: 1441px)": {
            marginTop: "50px",
            marginLeft: "auto",
            height: "420px",
          },
        }}
      />
    </Container>
  );
};

export default DressCode;
