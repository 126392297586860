/** @jsx jsx */
import { jsx } from "@emotion/react";
import { Col, Container, Row } from "react-bootstrap";
import {fonts } from '../../resources/styling'

export const AccomodationItem = ({ image, title, phone, email, link, distance }) => {
  return (
    <Container fluid className="m-0 p-0 pl-5 pr-5 mb-3">
      <Container className="p-0 m-0">
        <h4
          css={fonts.boxHeader}
        >
          {title}
        </h4>
        <Row className="w-100">
          <Col xs={12} lg={4} className="text-align-xs-left text-align-lg-center text-wrap">
            <div className="text-wrap" css={fonts.normalText}>
              Distance: {distance}
            </div>
          </Col>
          <Col xs={12} lg={4} className="text-align-xs-left text-align-lg-center text-wrap">
            <div className="text-wrap"css={fonts.normalText}>
              Email: {email}
            </div>
          </Col>
          <Col xs={12} lg={4} className="text-align-xs-left text-align-lg-center text-wrap">
            <div className="text-wrap"css={fonts.normalText}>
              Phone: {phone}
            </div>
          </Col>
        </Row>
      </Container>
      <a href={link}>
        <div
          className="m-0 p-0"
          css={{
            backgroundImage: `url(${image})`,
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "top center",
            height: "125px"
          }}
        />
      </a>
    </Container>
  );
};

export default AccomodationItem;
