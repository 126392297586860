/** @jsx jsx */
import { css } from '@emotion/react';
import { getInnerWindowHeight } from '../components/utils/Helpers';

export const colors = {
	bck_light: '#808080',
	bck_dark: '#222222',
	bck_white: '#ffffff',
	bck_red: '#ff4800',
	bck_yellow: '#ffa800',
	bck_blue: '#1f1c8e',
	bck_pink: '#f77fbe',
	bck_pale_pink: '#f28dcd',
	silverGradient: 'linear-gradient(180deg, #dedede 10%, #868686 40%, #dedede 80%)',
	bluePinkGradient: 'linear-gradient(180deg, #ff72f1 25%, #2f2bde 75%)',
};

export const shadows = {
	normal: '10px 10px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);',
	white: '10px 10px 8px 0 rgba(255, 255, 255, 0.2), 0 6px 20px 0 rgba(255, 255, 255, 0.19);',
};

export const fonts = {
	mainHeader: css({
		fontFamily: 'Alex Brush, cursive',
		'@media (max-width: 1440px)': {
			'@media (max-width: 767px)': {
				fontSize: '30px',
			},
			'@media (min-width: 768px)': {
				fontSize: '50px',
			},
		},
		'@media (min-width: 1441px)': {
			fontSize: '70px',
		},
	}),
	normalText: css({
		fontFamily: 'Monteserrat',
		color: 'white',
		fontWeight: 'regular',
		fontSize: '1rem',
		'@media (max-width: 1440px)': {
			fontFamily: 'Monteserrat',
			color: 'white',
			fontWeight: 'regular',
			fontSize: '0.8rem',
		},
	}),
	normalTextDark: css({
		fontFamily: 'Monteserrat',
		color: '#222222',
		fontWeight: 'regular',
		fontSize: '1rem',
		'@media (max-width: 1440px)': {
			fontFamily: 'Monteserrat',
			color: '#222222',
			fontWeight: 'regular',
			fontSize: '0.8rem',
		},
	}),
	centered: css({
		textAlign: 'center',
	}),
	underlined: css({
		textAlign: 'center',
		textDecoration: 'underline 1px solid white',
	}),
	sectionHeader: css({
		fontFamily: 'Monteserrat',
		color: 'white',
		fontWeight: 'regular',
		fontSize: '1.5rem',
		textAlign: 'center',
		textDecoration: 'underline 1px solid white',
	}),
	boxHeader: css({
		fontFamily: 'Alex Brush, cursive',
		color: 'white',
		fontWeight: 'regular',
		fontSize: '3rem',
		textAlign: 'center',
		textDecoration: 'underline 1px solid white',
		'@media (max-width: 360px)': {
			fontSize: '20px',
		},
	}),
};

export const sizing = {
	fullInnerHeight: css({
		minHeight: `${getInnerWindowHeight()}px`,
	}),
};
