/** @jsx jsx */
import "./resources/styles.css";
import { Element } from "react-scroll";
import { jsx } from "@emotion/react";
import Intro from "./components/intro";
import WhenWhere from "./components/whenWhere";
import Story from "./components/story";
import Footer from "./components/header_footer/Footer";
import Guests from "./components/guests";
import Menu from "./components/menu";
import Accomodation from "./components/accomodation";
import Rsvp from "./components/rsvp";
import SideBar from "./components/header_footer/SideBar";
import { useLoading, Hearts } from "@agney/react-loading";
import React, { useEffect, useState } from "react";
import {colors} from "./resources/styling";

const App = () => {
  const [loading, setLoading] = useState(true);

  const { containerProps, indicatorEl } = useLoading({
    loading: loading,
    loaderProps: {
      style: {
        backgroundColor: "#e74c3c",
        color: "white",
        position: "absolute",
        left: "50%",
        top: "50%",
        marginTop: "-140px",
        marginLeft: "-140px",
      },
    },
    indicator: <Hearts width="280px" height='280px' />,
  });

  useEffect(() => {
      if (loading) {
        setLoading(false);
      }
  }, [loading]);

  if (loading) {
    return (
      <div style={{height: '100vh', width: '100vw', backgroundColor: `${colors.bck_dark}`}}>
      <section {...containerProps} style={containerProps.style}>
        {indicatorEl} 
      </section>
      </div>
    );
  }

  return (
    <div className="p-0 m-0">
      <SideBar />

      <div className="p-0 m-0">
        <Element name="intro" className="d-block p-0 m-0 w-100">
          <Intro />
        </Element>

        <Element name="story" className="d-block p-0 m-0 w-100">
          <Story />
        </Element>

        <Element name="whenWhere" className="d-block p-0 m-0">
          <WhenWhere />
        </Element>

        <Element name="guests" className="d-block p-0 m-0">
          <Guests />
        </Element>

        <Element name="menu" className="d-block p-0 m-0">
          <Menu s />
        </Element>

        <Element name="accomodation" className="d-block p-0 m-0">
          <Accomodation />
        </Element>

        <Element name="rsvp" className="d-block p-0 m-0">
          <Rsvp />
        </Element>
      </div>

      <Footer />
    </div>
  );
};

export default App;
