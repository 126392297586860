import React, { useState } from "react";
import { Nav } from "react-bootstrap";
import Menu from "react-burger-menu/lib/menus/slide";
import { scroller } from "react-scroll";
import "./SideBar.css";
import {colors} from "../../resources/styling";

export const SideBar = (props) => {
  const menuStyling = {
    /* Individual item */
    bmItem: {
      display: "inline-block",

      /* Our sidebar item styling */
      textDecoration: "none",
      marginBottom: "10px",
      color: "#d1d1d1",
      transition: "color 0.2s",
      border: "none",
    },
    /* Change color on hover */
    bmItemhover: {
      color: "white",
      border: "1px solid white",
    },
    bmBurgerBars: {
      background: `${colors.silverGradient}`
    },
    /* Position and sizing of burger button */
    bmBurgerButton: {
      position: "fixed",
      width: "30px",
      height: "25px",
      left: "25px",
      top: "25px"
    },
    /* Position and sizing of clickable cross button */
    bmCrossButton: {
      height: "24px",
      width: "24px",
    },
    /* Color/shape of close button cross */
    bmCross: {
      background: "#bdc3c7",
    },
    /* General sidebar styles */
    bmMenu: {
      background: "#373a47",
      padding: "2.5em 1.5em 0",
      fontSize: "1.15em",
    },
    /* Morph shape necessary with bubble or elastic */
    bmMorphShape: {
      fill: "#373a47",
    },
    /* Wrapper for item list */
    bmItemList: {
      color: "#b8b7ad",
    },
    /* Styling of overlay */
    bmOverlay: {
      background: "rgba(0, 0, 0, 0.3)",
    },
  };

  const [isOpen, setIsOpen] = useState(false);
  const [selectedKey, setSelectedKey] = useState("intro");

  const scrollToElement = (element) => {
    setSelectedKey(element);
    scroller.scrollTo(element, {
        duration: 1300,
        delay: 100,
        smooth: true,
      });

    if (isOpen) {
      closeMenu();
    }
  };

  const handleStateChange = (state) => {
    setIsOpen(state.isOpen);
  };

  const closeMenu = () => {
    setIsOpen(false);
  };

  return (
    <Menu
      {...props}
      isOpen={isOpen}
      onStateChange={(state) => handleStateChange(state)}
      styles={menuStyling}
    >
      <Nav
        defaultActiveKey="intro"
        onSelect={(selectedKey) => scrollToElement(selectedKey)}
      >
        <Nav.Item as="li">
          <Nav.Link
            eventKey="intro"
            style={{
              color: `${selectedKey === "intro" ? "#007bff" : "white"}`,
            }}
          >
            Home
          </Nav.Link>
        </Nav.Item>
        <Nav.Item as="li">
          <Nav.Link
            eventKey="story"
            style={{
              color: `${selectedKey === "story" ? "#007bff" : "white"}`,
            }}
          >
            Our Story
          </Nav.Link>
        </Nav.Item>
        <Nav.Item as="li">
          <Nav.Link
            eventKey="whenWhere"
            style={{
              color: `${selectedKey === "whenWhere" ? "#007bff" : "white"}`,
            }}
          >
            When and Where
          </Nav.Link>
        </Nav.Item>
        <Nav.Item as="li">
          <Nav.Link
            eventKey="guests"
            style={{
              color: `${selectedKey === "guests" ? "#007bff" : "white"}`,
            }}
          >
            Additional Information
          </Nav.Link>
        </Nav.Item>
        <Nav.Item as="li">
          <Nav.Link
            eventKey="menu"
            style={{ color: `${selectedKey === "menu" ? "#007bff" : "white"}` }}
          >
            Menu
          </Nav.Link>
        </Nav.Item>
        <Nav.Item as="li">
          <Nav.Link
            eventKey="accomodation"
            style={{
              color: `${selectedKey === "accomodation" ? "#007bff" : "white"}`,
            }}
          >
            Accomodation
          </Nav.Link>
        </Nav.Item>
        <Nav.Item as="li">
          <Nav.Link
            eventKey="rsvp"
            style={{ color: `${selectedKey === "rsvp" ? "#007bff" : "white"}` }}
          >
            RSVP
          </Nav.Link>
        </Nav.Item>
      </Nav>
    </Menu>
  );
};

export default SideBar;
