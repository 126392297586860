/** @jsx jsx */
import { jsx, css } from '@emotion/react';
import { Fragment } from 'react';
import Zoom from 'react-reveal/Zoom';
import SectionHeader from '../utils/SectionHeader';

export const Title = ({ setMenuIconColor }) => {
	const styling = {
		title: css({
			width: '100%',
			textAlign: 'center',
			textShadow: '5px 6px 5px rgba(0, 0, 0, 0.8)',
			position: 'absolute',
			justifyContent: 'center',
			marginBottom: 0,
			paddingBottom: 0,
		}),
		top: css({
			top: '0px',
		}),
		bottom: css({
			bottom: '0px',
			'@media (max-width: 1200px)': {
				top: '60px',
			},
			'@media (min-width: 1201px)': {
				bottom: '35px',
			},
		}),
	};

	return (
		<Fragment>
			<div css={[styling.top, styling.title]}>
				<Zoom duration={1000}>
					<SectionHeader title='Getting Married' white={true} image={true} />
				</Zoom>
			</div>
			<div css={[styling.bottom, styling.title]}>
				<Zoom duration={1000}>
					<SectionHeader title='Nico & Tamara' white={true} />
				</Zoom>
			</div>
		</Fragment>
	);
};

export default Title;
