/** @jsx jsx */
import { jsx, css } from '@emotion/react';
import TimeUntil from './TimeUntil';
import { Title } from './title';
import { colors } from '../../resources/styling';
import { Page } from '../utils/Page';
import { getWindowHeight } from '../utils/Helpers';
import mainPic from '../../resources/images/main.jpg';

const imageStyle = css({
	position: 'relative',
	top: '0px',
	backgroundPosition: 'center',
	backgroundRepeat: 'no-repeat',
	backgroundSize: 'cover',
	maxHeight: '100vh',
	height: `${getWindowHeight()}px`,
	maxWidth: '100%',
	padding: '0px',
	margin: '0px',
});

const Intro = () => {
	return (
		<Page color={colors.bck_dark} className='p-0 m-0'>
			<img className='d-block mx-auto' src={mainPic} css={imageStyle} />
			<Title />
			<TimeUntil FontSize='20px' />
		</Page>
	);
};

export default Intro;
