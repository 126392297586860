/** @jsx jsx */
import { jsx } from "@emotion/react";
import { Container } from "react-bootstrap";
import { fonts, sizing } from "../../resources/styling";

const Description = () => {
  return (
    <Container
      fluid
      className="m-0 p-5"
      css={([fonts.normalText], sizing.fullInnerHeight)}
    >
      Despite traveling in the same small social circles for many years, Nico
      and Tamara never met. Both silently accepted the idea that love was not on
      the horizon. <br />
      <br />
      Until destiny intervened. They met at a mutual friend’s birthday and,
      despite not interacting a lot, Tamara, in her own special way, left a
      lasting impression on Nico. Destiny was not done with them, though, and
      brought them together once again, 2 weeks later. This time Nico vowed not
      to let her slip away again and made sure to get her number. It didn’t take
      long for a romance to blossom, but Tamara made sure to keep Nico on his
      toes and only acknowledged their relationship months later. <br />
      <br />
      In 2017 they travelled to Thailand, a dream Tamara never thought would
      come true. There they had some of the most amazing experiences, petting
      tigers and taking photos with dolphins, but most importantly, being away
      from the normal hustle and bustle, they truly got to know each other and
      so began their heartfelt relationship. strengthened their relationship
      even further. <br />
      <br />
      Since they met 5 years ago, they have experienced so much together.
      Unforgettable holidays and breakaways, amazing times with friends both old
      and new, music concerts, taking enough photos to fill a museum and living
      together the past 2 years. <br />
      <br />
      Nico and Tamara have finally found what they thought to be impossible, and
      on 16 April they will both be saying “I Do” in front of their closest
      friends and family.
    </Container>
  );
};

export default Description;
