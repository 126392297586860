/** @jsx jsx */
import { jsx, css } from "@emotion/react";
import { Container } from "react-bootstrap";
import Zoom from "react-reveal/Zoom";

const ItemStyling = {
  item: css({
    width: "50%",
    color: "#ffffff",
    textAlign: "center",
  }),
  outer: css({
    width: "350px",
    "@media (max-width: 575px)": {
      width: "200px",
    },
    border: "2px solid white",
    margin: "0px",
    padding: "5px",
  }),
  inner: css({
    border: "2px solid white",
    position: "relative",
    padding: "0px 20px",
    transition: "all 500ms ease",
    "&:hover": {
      background:
        "linear-gradient(180deg, #dedede 10%, #868686 40%, #dedede 80%)",
      backgroundColor: "white",
      backgroundSize: "contain",
    },
  }),
  iconSquare: css({
    width: "100px",
    height: "100px",
    position: "absolute",
    transform: "rotate(45deg)",
    top: "-54px",
    left: "120px",
    margin: '0px',
    padding: '0px',
    "@media (max-width: 575px)": {
      width: "50px",
      height: "50px",
      top: "-30px",
      left: "69px",
    },
  }),
  icon: css({
    width: "50px",
    height: "50px",
    backgroundSize: "contain !important",
    backgroundRepeat: "no-repeat !important",
    position: "absolute",
    top: "-30px",
    left: "144px",
    "@media (max-width: 575px)": {
      width: "25px",
      height: "25px",
      top: "-18px",
      left: "81px",
    },
  }),
  title: css({
    fontSize: "28px",
    margin: "90px 0px 20px 0px",
    borderBottom: "1px solid #5d5d5d",
    paddingBottom: "10px",
    "@media (max-width: 575px)": {
      fontSize: "19px",
      paddingBottom: "5px",
      margin: "45px 0px 10px 0px"
    },
  }),
  desc: css({
    fontSize: "30px",
    fontWeight: "300",
    marginBottom: "90px",
    "@media (max-width: 575px)": {
      fontSize: "20px",
      marginBottom: "45px",
    }
  }),
}

export const WhenWhereItem = ({
  wrapper,
  fillColor,
  icon,
  title,
  description,
  iconFill,
  click,
  css,
  className
}) => {
  return (
    <Container fluid={'true'} className={`d-md-block justify-content-between mt-3 mb-3 ${className}`} css={[wrapper,css]} onClick={click}>
      <Zoom duration={500}>
        <div css={ItemStyling.item}>
          <div css={ItemStyling.outer} className="mx-auto">
            <div css={[ItemStyling.inner, { backgroundColor: fillColor }]}>
              <div
                css={[ItemStyling.iconSquare, { backgroundColor: iconFill }]}
              ></div>
              <div
                css={ItemStyling.icon}
                style={{
                  background: `url(${icon})`,
                }}
              ></div>
              <div css={ItemStyling.title}>{title}</div>
              <div css={ItemStyling.desc}>{description}</div>
            </div>
          </div>
        </div>
      </Zoom>
    </Container>
  );
}

export default WhenWhereItem;