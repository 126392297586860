/** @jsx jsx */
import { css } from '@emotion/react';
import bckGround from '../../resources/images/venue.png';

const styling = {
	wrapper: css({
		padding: '0',
		paddingTop: '20px',
		paddingBottom: '20px',
		margin: 'auto',
		'@media (max-width: 1200px)': {
			marginLeft: 'auto',
			marginRight: 'auto',
		},
		'@media (min-width: 1201px)': {
			margin: '0',
			marginLeft: 'auto',
			marginRight: 'auto',
		},
	}),
	background: css({
		backgroundImage: `url(${bckGround})`,
		backgroundSize: 'cover',
		backgroundRepeat: 'no-repeat',
		backgroundPosition: 'center',
	}),
	item: css({
		width: '50%',
		color: '#ffffff',
		textAlign: 'center',
	}),
	outer: css({
		width: '500px',
		'@media (max-width: 575px)': {
			width: '300px',
		},
		border: '2px solid white',
		margin: 'auto',
		padding: '5px',
	}),
	inner: css({
		border: '2px solid white',
		position: 'relative',
		padding: '15px',
		paddingTop: '5px',
		transition: 'all 500ms ease',
	}),
};

export default styling;
