/** @jsx jsx */
import { jsx } from "@emotion/react";
import { Container } from "react-bootstrap";
import { getWindowHeight } from "./Helpers";

export const Page = ({ children, color, headerVisible }) => {
  return (
    <Container
      fluid
      className={"p-0 mx-auto w-100" + (headerVisible ? 'pt-2 pb-4 ' : '')}
      css={{
        backgroundColor: color,
        minHeight: `${getWindowHeight()}px`
      }}
    >
      {children}
    </Container>
  );
};
