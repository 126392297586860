/** @jsx jsx */
import { jsx } from "@emotion/react";
import { Col, Row } from "react-bootstrap";
import Description from "./Description";
import Carrousel from "./Carrousel";
import SectionHeader from "../utils/SectionHeader";
import { Page } from "../utils/Page";
import { ContentBox } from "../utils/ContentBox";

const Story = () => {


  return (
    <Page headerVisible={true} color="white">
      <SectionHeader title="Our Story" underline={true} image={true} />
      <Row fluid="true" className="p-0 m-0 pl-3 pr-3 pb-lg-4">
        <Col lg={12} xl={6} className="m-0 mt-3 h-100 mb-xl-0 p-0 pr-xl-4">
          <ContentBox>
            <Description />
          </ContentBox>
        </Col>
        <Col lg={12} xl={6} className="m-0 mt-3 h-100 mb-xl-0 p-0 pl-xl-4">
          <ContentBox>
            <Carrousel />
          </ContentBox>
        </Col>
      </Row>
    </Page>
  );
};

export default Story;
