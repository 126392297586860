/** @jsx jsx */
import { jsx, css } from '@emotion/react';
import { useState } from 'react';
import { Carousel, Container, Image } from 'react-bootstrap';
import { colors, shadows } from '../../resources/styling';
import { getInnerWindowHeight, getWindowHeight } from '../utils/Helpers';

const imageStyle = css({
	position: 'relative',
	'@media (min-width: 1200px)': {
		minHeight: `${getInnerWindowHeight() - 8}px`,
		maxHeight: `${getInnerWindowHeight() - 8}px`,
	},
	'@media (max-width: 1199px)': {
		minHeight: `${getWindowHeight()}px`,
	},
	maxWidth: '100%',
	padding: '0px',
	margin: '0px',
	objectFit: 'contain',
});

const Carrousel = () => {
	const [index, setIndex] = useState(0);

	const handleSelect = (selectedIndex, e) => {
		setIndex(selectedIndex);
	};

	const images = [
		`${process.env.PUBLIC_URL}/assets/images/n01.jpg`,
		`${process.env.PUBLIC_URL}/assets/images/n02.jpg`,
		`${process.env.PUBLIC_URL}/assets/images/n03.jpg`,
		`${process.env.PUBLIC_URL}/assets/images/n04.jpg`,
		`${process.env.PUBLIC_URL}/assets/images/n05.jpg`,
		`${process.env.PUBLIC_URL}/assets/images/n06.jpg`,
		`${process.env.PUBLIC_URL}/assets/images/n07.jpg`,
		`${process.env.PUBLIC_URL}/assets/images/n08.jpg`,
		`${process.env.PUBLIC_URL}/assets/images/n09.jpg`,
		`${process.env.PUBLIC_URL}/assets/images/n10.jpg`,
		`${process.env.PUBLIC_URL}/assets/images/n11.jpg`,
		`${process.env.PUBLIC_URL}/assets/images/n12.jpg`,
		`${process.env.PUBLIC_URL}/assets/images/n13.jpg`,
		`${process.env.PUBLIC_URL}/assets/images/n14.jpg`,
		`${process.env.PUBLIC_URL}/assets/images/n15.jpg`,
		`${process.env.PUBLIC_URL}/assets/images/n16.jpg`,
		`${process.env.PUBLIC_URL}/assets/images/n17.jpg`,
		`${process.env.PUBLIC_URL}/assets/images/n18.jpg`,
	];

	return (
		<Container fluid className='m-0 p-0'>
			<Carousel
				activeIndex={index}
				onSelect={handleSelect}
				interval={2000}
				className='w-100 m-0 p-0 pl-md-5 pr-md-5'
				controls={true}
				css={{
					backgroundColor: [colors.bck_dark],
					boxShadow: [shadows.normal],
				}}>
				{images.map((i, k) => (
					<Carousel.Item key={k}>
						<Image className='d-block mx-auto pb-0 p-md-5' alt='slide' src={i} css={imageStyle} />
					</Carousel.Item>
				))}
			</Carousel>
		</Container>
	);
};

export default Carrousel;
